import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as es from './es.json';
import * as en from './en.json';

const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'es',
    interpolation: {
        escapeValue: false
    },
    react: {
        bindI18n: 'languageChanged',
        useSuspense: false
    }
});

export default i18n;
